var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "photo-book-cus-box",
    staticStyle: {
      "background-color": "#E8E4E4"
    }
  }, [_c('back-off', {
    attrs: {
      "url": _vm.backUrl,
      "title": "Preview"
    }
  }), _c('br'), _c('br'), _vm._l(_vm.pictureList, function (item, index) {
    return [_c('div', {
      key: index + 100,
      class: {
        'odd': index % 2 === 0,
        'even': index % 2 === 1
      },
      style: {
        width: _vm.coverageWidth,
        height: _vm.coverageHeight
      },
      on: {
        "click": function ($event) {
          return _vm.selectPicture(index);
        }
      }
    }, [_c('div', {
      style: {
        width: _vm.coverageWidth,
        height: _vm.coverageHeight
      },
      attrs: {
        "id": 'custom-user-image-option-box-' + index + '-area'
      }
    }, [(_vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.url : false) ? _c('van-image', {
      style: {
        top: _vm.pictureInfoList[index].userImgInfo[0].top,
        left: _vm.pictureInfoList[index].userImgInfo[0].left
      },
      attrs: {
        "src": _vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.url : '0',
        "width": _vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.width : '0',
        "height": _vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.height : '0',
        "display": "inline-block"
      }
    }) : _vm._e()], 1)]), index % 2 == 1 ? _c('div', {
      key: index,
      staticStyle: {
        "display": "flex"
      }
    }, [_c('span', {
      staticStyle: {
        "margin-left": "25vw",
        "margin-top": "1vw",
        "margin-bottom": "1vw"
      }
    }, [_vm._v(_vm._s(`-${index}-`))]), _c('span', {
      staticStyle: {
        "margin-left": "40vw",
        "margin-top": "1vw",
        "margin-bottom": "1vw"
      }
    }, [_vm._v(_vm._s(`-${index + 1}-`))])]) : _vm._e()];
  }), _c('br')], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };